<template>
  <div class="auction">
    <div class="main">
      <div class="main-breadcrumb p-2">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/newbidding' }"
            >竞价大厅
          </el-breadcrumb-item>
          <el-breadcrumb-item>竞价交易</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="main-body">
        <el-card shadow="never">
          <el-row :gutter="5">
            <el-col :span="8">
              <Carousel :imgSrc="imgSrc" :imagesList="imagesList" />
            </el-col>
            <el-col :span="16">
              <div
                style="
                  font-size: 15px;
                  color: red;
                  magin-left: 50px;
                  margin: 0px 0px 0px 0px;
                "
              >
                <span>项目编号：</span>{{ projectno }}
              </div>
              <div
                style="
                  font-size: 20px;
                  magin-left: 50px;
                  margin: 0px 0px 25px 0px;
                "
              >
                {{ targetname }}
              </div>
              <div style="margin-left: 50px; margin-top: 5px; height: 30px">
                <eol--row :gutter="5">
<!--                  原逻辑判断  显示挂牌价  成交价  待确认  等等-->
<!--                  <el-col :span="16">-->
<!--                    <div style="font-size: 18px" v-if="shw == 1">-->
<!--                      <span v-if="outbidstate === 2" style="color: #e34545">已成交</span>-->
<!--                      <span v-else>挂牌价：</span>-->
<!--                      <span-->
<!--                        class="currentPrice"-->
<!--                        style="color: #e34545"-->
<!--                        v-if="outbidstate != 2"-->
<!--                        >{{ price }}<span>{{ unitname }}</span></span-->
<!--                      >-->
<!--                    </div>-->
<!--                    <div style="font-size: 18px" v-else>-->
<!--                      <span v-if="outbidstate === 2" style="color: #e34545">已成交</span>-->
<!--                      <span v-else>挂牌价：</span>-->
<!--                      <span class="currentPrice" style="color: #e34545">{{-->
<!--                        shwword-->
<!--                      }}</span>-->
<!--                    </div>-->
<!--                  </el-col>-->

                  <!--   新逻辑判断  显示挂牌价  成交价  待确认  等等-->
                  <el-col :span="16">
                    <div style="font-size: 18px" v-if="outbidstate === 2">
                      <span style="color: #e34545">已成交</span>
                    </div>
                    <div style="font-size: 18px;color: #e34545;" v-else>
                      <span style="color: black;">挂牌价：</span>
                      <span v-if="shw == 1">
                         <span class="currentPrice">{{ price.toFixed(3) }}<span>{{ unitname }}</span></span>
                      </span>
                      <span v-else>
                         <span class="currentPrice">{{shwword}}</span>
                      </span>
                    </div>
                  </el-col>

                  <el-col :span="8">
                    <div style="display: -webkit-flex; display: flex">
                      <div style="flex: 1">
                        <i
                          v-if="!isCollections"
                          style="font-size: 16px; cursor: pointer"
                          class="el-icon-star-off"
                          @click="collections(1)"
                        >
                          <span style="font-size: 14px; margin-left: 5px"
                            >收藏</span
                          >
                        </i>
                        <i
                          v-if="isCollections"
                          style="font-size: 16px; cursor: pointer"
                          class="el-icon-star-on"
                          @click="collections(2)"
                        >
                          <span style="font-size: 14px; margin-left: 5px"
                            >取消收藏</span
                          >
                        </i>
                      </div>
                      <div style="flex: 1">
                        <i
                          style="font-size: 16px; cursor: pointer"
                          class="el-icon-question"
                        >
                          <span
                            style="
                              font-size: 14px;
                              margin-left: 5px;
                              cursor: pointer;
                            "
                            @click="gotohelp()"
                            >竞拍帮助</span
                          >
                        </i>
                      </div>
                    </div>
                  </el-col>
                </eol--row>
              </div>
              <div style="margin-left: 50px; margin-top: 5px; height: 30px">
                <eol--row :gutter="5">
                  <el-col :span="12">
                    <div style="font-size: 18px" v-if="outbidstate === 0">
                      <span>距开始：</span>
                      <span style="color: green">{{ countDownTime }}</span>
                    </div>
                    <div style="font-size: 18px" v-if="outbidstate === 1">
                      <span>距结束：</span>
                      <span style="color: #e34545">{{ countDownTime }}</span>
                    </div>
                    <div style="font-size: 18px" v-if="outbidstate === 2">
                      <span>已拍下：</span>
                      <span style="color: #f56c6c">{{ endtime }}</span>
                    </div>
                    <div style="font-size: 18px" v-if="outbidstate === 3">
                      <span>已流拍：</span>
                      <span style="color: #f56c6c">{{ endtime }}</span>
                    </div>
                    <div style="font-size: 18px" v-if="outbidstate === 4">
                      <span>等待竞价结果中</span>
                    </div>
                    <div style="font-size: 18px" v-if="outbidstate === 5">
                      <span>公示期：</span>
                      <span style="color: #f56c6c">{{ countDownTime }}</span>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div style="font-size: 18px" v-if="outbidstate === 5 || outbidstate === 0 || outbidstate === 1">
                      <span >起拍时间：</span>
                      <span style="color: #f56c6c">{{ begintime }}</span>
                    </div>
                  </el-col>
                </eol--row>
              </div>
              <div
                style="
                  margin-left: 50px;
                  margin-top: 5px;
                  height: 150px;
                  border: 1px solid #e5e4e4;
                  position: relative;
                "
              >
                <div
                  style="
                    position: absolute;
                    width: 15%;
                    height: 20%;
                    right: 0px;
                    top: 0px;
                    z-index: 100;
                    background-color: #1055de;
                  "
                >
                  <p
                    style="text-align: center; color: #ffffff; margin-top: 3px"
                    v-if="outbidstate === 0"
                  >
                    未开始
                  </p>
                  <p
                    style="text-align: center; color: #ffffff; margin-top: 3px"
                    v-if="outbidstate === 1"
                  >
                    竞价中
                  </p>
                  <p
                    style="text-align: center; color: #ffffff; margin-top: 3px"
                    v-if="outbidstate === 2"
                  >
                    已拍下
                  </p>
                  <p
                    style="text-align: center; color: #ffffff; margin-top: 3px"
                    v-if="outbidstate === 3"
                  >
                    已流拍
                  </p>
                  <p
                    style="text-align: center; color: #ffffff; margin-top: 3px"
                    v-if="outbidstate === 4"
                  >
                    等待
                  </p>
                  <p
                    style="text-align: center; color: #ffffff; margin-top: 3px"
                    v-if="outbidstate === 5"
                  >
                    公示期
                  </p>
                </div>
                <div style="display: -webkit-flex; display: flex">
                  <div style="margin: 30px 0px 0px 30px; width: 50px">
                    <span style="font-size: 18px; top: 55%">出价</span>
                  </div>
                  <div style="margin-top: 30px; text-align: center">
                    <el-button
                      icon="el-icon-remove-outline"
                      style="display: inline-block"
                      @click="subrprice()"
                      :disabled="dis"
                    ></el-button>
                    <el-input
                      class="test"
                      v-model="inputjiage"
                      placeholder="请输入"
                      style="
                        display: inline-block;
                        width: 150px;
                        text-align: center;
                      "
                      readonly="true"
                    >
                      <span
                        slot="suffix"
                        style="display: inline-block; margin: 8px 0 0 -45px"
                        >{{ unitname }}</span
                      >
                    </el-input>
                    <el-button
                      icon="el-icon-circle-plus-outline"
                      style="display: inline-block"
                      @click="addprice()"
                      :disabled="dis"
                    ></el-button>
                  </div>
                  <div style="margin: 30px 0px 0px 30px; width: 50px">
                    <span style="font-size: 18px; top: 55%">出量</span>
                  </div>
                  <div style="margin-top: 30px; text-align: center">
                    <el-button
                      icon="el-icon-remove-outline"
                      style="display: inline-block"
                      @click="subrQuantity()"
                      :disabled="dis"
                    ></el-button>
                    <el-input
                      class="test"
                      v-model="inputQuantity"
                      placeholder="请输入"
                      style="
                        display: inline-block;
                        width: 150px;
                        text-align: center;
                      "
                      readonly="true"
                    >
                      <span
                        slot="suffix"
                        style="display: inline-block; margin: 8px 0 0 -45px"
                        >{{ unitname }}</span
                      >
                    </el-input>
                    <el-button
                      icon="el-icon-circle-plus-outline"
                      style="display: inline-block"
                      @click="addQuantity()"
                      :disabled="dis"
                    ></el-button>
                  </div>
                </div>
                <div style="display: -webkit-flex; display: flex">
                  <div style="margin: 10px 0px 0px 95px">
                    <el-button
                      type="danger"
                      style="width: 150px"
                      @click="application()"
                      v-if="outbidstate == 5"
                      >踏勘申请
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 150px"
                      disabled
                      v-if="baomingflg === 7 && [5].includes(outbidstate)"
                      >已报名
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 150px; margin-left: 15px"
                      @click="beforebaoming()"
                      v-if="baomingflg === 0 && [5].includes(outbidstate)"
                      >我要报名
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 315px"
                      v-if="baomingflg === 1 && [5].includes(outbidstate)"
                      @click="uploadMoreFile()"
                      >资料审核中
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 315px"
                      @click="baoming()"
                      v-if="baomingflg === 2 && [5].includes(outbidstate)"
                      >缴纳保证金
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 315px"
                      disabled
                      v-if="baomingflg === 3 && [5].includes(outbidstate)"
                      >已驳回
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 315px"
                      v-if="baomingflg === 4 && [5].includes(outbidstate)"
                      @click="uploadMoreFile()"
                      >保证金审核中
                    </el-button>
                    <!-- <el-button  type="danger"  style="width: 315px"
                                          disabled  v-if="baomingflg===5 && ([0,1].includes(outbidstate))">已驳回</el-button
                                        > -->
                    <el-button
                      type="danger"
                      style="width: 315px"
                      disabled
                      v-if="baomingflg === 6 && [0, 5].includes(outbidstate)"
                      >等待报价
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 315px"
                      @click="baojia()"
                      v-if="baomingflg === 5 && outbidstate === 1"
                      >报价
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 315px"
                      disabled
                      v-if="outbidstate === 2"
                      >已结束
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 315px"
                      disabled
                      v-if="outbidstate === 3"
                      >已结束
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 315px"
                      disabled
                      v-if="outbidstate === 4"
                      >等待结束
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 315px"
                      @click="gotoLogin()"
                      v-if="
                        baomingflg === 99 && ![2, 3, 4, 5].includes(outbidstate)
                      "
                      >登录
                    </el-button>
                    <el-button
                      type="danger"
                      style="width: 150px"
                      @click="gotoLogin()"
                      v-if="baomingflg === 99 && [5].includes(outbidstate)"
                      >登录
                    </el-button>
                  </div>
                </div>
              </div>
              <!-- 标的详情 -->
              <div style="margin-top: 20px; margin-left: 50px; width: 100%">
                <span
                  style="
                    display: inline-block;
                    margin-left: 30%;
                    margin-bottom: 20px;
                    color: red;
                    font-size: 22px;
                    font-weight: 600;
                  "
                  >出价无法修改，谨慎出价</span
                >
                <el-row :gutter="5">
                  <el-col :span="8">
                    <div
                      style="width: 100%; display: -webkit-flex; display: flex"
                    >
                      <p style="font-size: 14px">挂牌价：</p>
                      <p
                        style="font-size: 14px; margin-top: -8px"
                        v-if="shw == 1"
                      >
                        <span class="currentPrice" style="color: #f56c6c"
                          >{{ price.toFixed(3)
                          }}<span>{{ unitname }}</span></span
                        >
                      </p>
                      <p style="font-size: 14px; margin-top: -8px" v-else>
                        <span class="currentPrice" style="color: #f56c6c">{{
                          shwword
                        }}</span>
                      </p>
                    </div>
                    <div
                      v-if="forecast > 1"
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">预估量：</p>
                      <p style="font-size: 14px; margin-top: -4px">
                        <span
                          class="currentPrice"
                          style="
                            color: #f56c6c;
                            font-size: 23px;
                            font-family: 'Times New Roman', Times, serif;
                            font-weight: 600;
                          "
                          >{{ forecast
                          }}<span>{{ subtempabc(unitname) }}</span></span
                        >
                      </p>
                    </div>
                    <div
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">自由竞价周期：</p>
                      <p style="font-size: 14px">
                        {{
                          freebidcycle == null ||
                          freebidcycle == 0 ||
                          freebidcycle == ""
                            ? "--"
                            : freebidcycle
                        }}分钟
                      </p>
                    </div>
                    <div
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">限时竞价周期：</p>
                      <p style="font-size: 14px">
                        {{
                          limitedbidcycle == null ||
                          limitedbidcycle == 0 ||
                          limitedbidcycle == ""
                            ? "--"
                            : limitedbidcycle
                        }}分钟/次
                      </p>
                    </div>
                    <div
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">竞拍模式：</p>
                      <p style="font-size: 14px">拆分拍</p>
                    </div>
                    <div
                      v-if="forecast == 1"
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">项目所在地：</p>
                      <p style="font-size: 14px">{{ areaname }}</p>
                    </div>
                    <div
                        style="width: 100%; display: -webkit-flex; display: flex;margin-top: 15px;"
                    >
                      <p style="font-size: 14px">是否强制踏勘：</p>
                      <p style="font-size: 14px;">
                        {{ survey == '1'? '是':'否' }}
                      </p>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div
                      style="width: 100%; display: -webkit-flex; display: flex"
                    >
                      <p style="font-size: 14px">加价幅度：</p>
                      <p style="font-size: 14px; margin-top: -4px">
                        <span
                          class="currentPrice"
                          style="
                            color: #f56c6c;
                            font-size: 23px;
                            font-family: 'Times New Roman', Times, serif;
                            font-weight: 600;
                          "
                          >{{ minmarkup.toFixed(3)
                          }}<span>{{ unitname }}</span></span
                        >
                      </p>
                    </div>
                    <div
                      v-if="htype == 0"
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">卡值：</p>
                      <p style="font-size: 14px; margin-top: -4px">
                        <span
                          class="currentPrice"
                          style="
                            color: #f56c6c;
                            font-size: 23px;
                            font-family: 'Times New Roman', Times, serif;
                            font-weight: 600;
                          "
                          >{{ power }}<span>大卡</span></span
                        >
                      </p>
                      <!-- <p style="font-size: 14px">{{ power }}</p> -->
                    </div>
                    <div
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">交易保证金：</p>
                      <p style="font-size: 14px">{{ deposit }}元</p>
                    </div>
                    <div
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">项目联系人：</p>
                      <p style="font-size: 14px">{{ consultation }}</p>
                    </div>
                    <div
                      v-if="forecast > 1"
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">项目所在地：</p>
                      <p style="font-size: 14px">{{ areaname }}</p>
                    </div>

                    <div
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">起拍数量：</p>
                      <p style="font-size: 14px">{{ minquantity }}吨</p>
                    </div>
                    <!--                    <div-->
                    <!--                      style="width:100%  display: -webkit-flex; display: flex; margin-top:15px"-->
                    <!--                    >-->
                    <!--                      <p style="font-size: 14px">拍卖师：</p>-->
                    <!--                      <p style="font-size: 14px">{{ auctioneer }}</p>-->
                    <!--                      <el-link-->
                    <!--                        type="danger"-->
                    <!--                        style="margin-left: 5px"-->
                    <!--                        @click="centerDialogVisible = true"-->
                    <!--                      >-->
                    <!--                        查看资质</el-link-->
                    <!--                      >-->
                    <!--                    </div>-->
                  </el-col>
                  <el-col :span="8">
                    <div
                      style="width: 100%; display: -webkit-flex; display: flex"
                    >
                      <p style="font-size: 14px">类型：</p>
                      <p style="font-size: 14px">网络竞价</p>
                    </div>
                    <!--                    <div-->
                    <!--                      style="width:100%  display: -webkit-flex; display: flex; margin-top:15px"-->
                    <!--                    >-->
                    <!--                      <p style="font-size: 14px">评估价：</p>-->
                    <!--                      <p style="font-size: 14px">{{ evaprice }}元</p>-->
                    <!--                    </div>-->
                    <div
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">服务费比例：</p>
                      <p style="font-size: 14px">{{ servicecharge }}%</p>
                    </div>
                    <div
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">联系电话：</p>
                      <p style="font-size: 14px">{{ consultationmode }}</p>
                    </div>
                    <div
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">是否含税：</p>
                      <p style="font-size: 14px">
                        {{ shifouhanshuiis(shifouhanshui) }}
                      </p>
                    </div>
                    <div
                      style="
                        width: 100%;
                        display: -webkit-flex;
                        display: flex;
                        margin-top: 15px;
                      "
                    >
                      <p style="font-size: 14px">最低加量：</p>
                      <p style="font-size: 14px">{{ minbquantity }}</p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </el-card>
        <!--        踏勘申请-->
        <el-dialog title="踏勘申请" :visible.sync="surveydialogFormVisible">
          <el-form :model="surveyData" :rules="dataRule" ref="surveyData">
            <el-form-item label="企业名称" prop="surveyentname">
              <el-input
                v-model="surveyData.surveyentname"
                placeholder="企业名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="踏勘人姓名" prop="surveyname">
              <el-input
                v-model="surveyData.surveyname"
                placeholder="踏勘人姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="tel">
              <el-input
                v-model="surveyData.tel"
                placeholder="联系电话"
              ></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="surveydialogFormVisible = false"
              >取 消</el-button
            >
            <el-button type="primary" @click="surveydialogSubmit()"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <el-dialog
          :title="baoMingParam"
          :visible.sync="baomingVisible"
          :class="dialogStyle ? 'pub_dialog_red' : 'pub_dialog'"
        >
          <el-steps :active="active" finish-status="success">
            <el-step title="递交报名材料"></el-step>
            <el-step title="报名材料审核"></el-step>
            <el-step title="上传交易保证金汇款凭证"></el-step>
            <!--                        <el-step title="上传汇款凭证"></el-step>-->
          </el-steps>
          <el-form :model="form" v-if="active == 0 || active == 1" label-position="top">
            <el-row>
              <el-col :span="24">

                <el-form-item label="附件上传">
                  <!--                  :on-preview="handlePreview"
                                    :on-remove="handleRemove"-->
                  <div>
                    <el-upload
                        class="upload-demo"
                        action=""
                        multiple
                        :http-request="upload"
                        :on-preview="openLink"
                        :before-remove="removeElement"
                        :file-list="fileInfos">
                      <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                  </div>

                  <!--       <div v-if="currentFile" class="progress">
                           <div
                             class="progress-bar progress-bar-info progress-bar-striped"
                             role="progressbar"
                             :aria-valuenow="progress"
                             aria-valuemin="0"
                             aria-valuemax="100"
                             :style="{ width: progress + '%' }"
                           >
                             {{ progress }}%
                           </div>
                         </div>
                         <div class="card">
                           <label class="btn btn-default">
                             <input type="file" ref="file" @change="selectFile" />
                           </label>

                           <button
                             class="btn btn-success"
                             :disabled="!selectedFiles"
                             @click="upload"
                           >
                             点击上传
                           </button>
                         </div>

                         <div class="alert alert-light" role="alert">
                           {{ message }}
                         </div>-->
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>
          <el-form :model="form" v-if="active == 2">
            <el-form-item label="保证金缴费" prop="fileaddres">
              <!--              <el-input v-model="dataForm.fileaddress" placeholder="拍卖师证照片"></el-input>-->
              <!--                            <el-upload-->
              <!--                                    class="avatar-uploader"-->
              <!--                                    :action="url"-->
              <!--                                    :show-file-list="false"-->
              <!--                                    :on-success="handleAvatarSuccess"-->
              <!--                                    :before-upload="beforeAvatarUpload">-->
              <!--                                <img v-if="imagedataForm.fileaddres" :src="imageUrl" class="avatar">-->
              <!--                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
              <!--                            </el-upload>-->
              <el-row>
                <el-col :span="24">
                  <el-form-item label="附件上传">
                    <div>
                      <el-upload
                          class="upload-demo"
                          action=""
                          multiple
                          :http-request="upload2"
                          :on-preview="openLink"
                          :before-remove="removeElement2"
                          :file-list="fileInfos2">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                      </el-upload>
                    </div>
                    <!--        <div v-if="currentFile2" class="progress">
                              <div
                                class="progress-bar progress-bar-info progress-bar-striped"
                                role="progressbar"
                                :aria-valuenow="progress2"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                :style="{ width: progress2 + '%' }"
                              >
                                {{ progress2 }}%
                              </div>
                            </div>
                            <div class="card">
                              <label class="btn btn-default">
                                <input type="file" ref="file2" @change="selectFile2" />
                              </label>

                              <button
                                class="btn btn-success"
                                :disabled="!selectedFiles2"
                                @click="upload2"
                              >
                                点击上传
                              </button>
                            </div>

                            <div class="alert alert-light" role="alert">
                              {{ message }}
                            </div>-->
                  </el-form-item>
                </el-col>
              </el-row>
              <!--<el-row>-->
                <!--<el-col :span="24">-->
                  <!--<el-form-item label="附件列表">-->
                    <!--<el-table-->
                        <!--:data="fileInfos2"-->
                        <!--style="width: 100%"-->
                        <!--max-height="250"-->
                    <!--&gt;-->
                      <!--<el-table-column prop="name" label="附件">-->
                        <!--<template slot-scope="scope">-->
                          <!--<a :href="scope.row.url">{{ scope.row.name }}</a>-->
                        <!--</template>-->
                      <!--</el-table-column>-->
                      <!--<el-table-column-->
                          <!--v-if="!readonly3"-->
                          <!--fixed="right"-->
                          <!--label="操作"-->
                          <!--width="120"-->
                      <!--&gt;-->
                        <!--<template slot-scope="scope">-->
                          <!--<el-button-->
                              <!--@click.native.prevent="-->
                              <!--removeElement2(scope.$index, scope.row.name)-->
                            <!--"-->
                              <!--type="text"-->
                              <!--size="small"-->
                          <!--&gt;-->
                            <!--移除-->
                          <!--</el-button>-->
                        <!--</template>-->
                      <!--</el-table-column>-->
                    <!--</el-table>-->
                  <!--</el-form-item>-->
                <!--</el-col>-->
              <!--</el-row>-->
            </el-form-item>
          </el-form label-position="top">
          <span slot="footer" class="dialog-footer">
            <el-button
              style="margin-top: 12px"
              v-if="baomingButtondis"
              @click="next"
              >{{ baomingButton }}</el-button
            >
            <el-button @click="baomingVisible = false">关 闭</el-button>
          </span>
        </el-dialog>
        <el-dialog
          title="资质"
          :visible.sync="centerDialogVisible"
          class="pub_dialog"
        >
          <el-image :src="auctioneerFileaddress" :fit="contain"></el-image>
          <span slot="footer" class="dialog-footer">
            <el-button @click="centerDialogVisible = false">关 闭</el-button>
          </span>
        </el-dialog>
        <!-- 竞价过程 -->
        <el-card v-if="outbidstate == 1">
          <el-table style="width: 100%" :data="guochengList">
            <el-table-column label="出价人" prop="maxUser" align="center">
              <template slot-scope="scope">
                <span>{{ getMemberNamemaxUser(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="出价价格" prop="maxPrice" align="center">
              <template slot-scope="scope">
                <span
                  v-if="scope.$index == 0"
                  class="currentPrice2"
                  style="color: #f56c6c"
                  >{{ scope.row.maxPrice.toFixed(3) }}</span
                >
                <span v-else class="currentPrice3" style="color: blue">{{
                  scope.row.maxPrice.toFixed(3)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="数量" prop="maxQuantity" align="center">
              <template slot-scope="scope">
                <span
                  v-if="scope.$index == 0"
                  class="currentPrice2"
                  style="color: #f56c6c"
                  >{{ scope.row.maxQuantity.toFixed(3) }}</span
                >
                <span v-else class="currentPrice3" style="color: blue">{{
                  scope.row.maxQuantity.toFixed(3)
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="出价时间"
              prop="maxPriceTime2"
              align="center"
            ></el-table-column>
            <!-- <template slot-scope="scope">
                        <span>{{ scope.row.maxPriceTime }}</span>
                        </template> -->
            <el-table-column
              label="出价次数"
              prop="maxPriceCount"
              align="center"
            ></el-table-column>
          </el-table>
        </el-card>
        <!-- 竞价结果 -->
        <el-card v-if="outbidstate == 2">
          <div class="item-4">
            <div v-if="memberidqtdis">
              <span style="color: red; font-size: 22px"
                >前往-<span
                  style="
                    display: inline-block;
                    cursor: pointer;
                    font-weight: 700;
                  "
                  @click="mine(1)"
                  ><span style="color: #409eff">用户中心</span></span
                >-查看竞拍结果及下一步流程。</span
              >
            </div>
            <div class="item-4-table" align="center">
              <div>
                <div class="item-4-prompt p-2" style="background-color: #fff">
                  <i class="el-icon-medal-1"></i>竞价结果
                </div>
                <el-table
                  class="w-100 dis-ib"
                  style="width: 100%"
                  :data="participate"
                >
                  <el-table-column
                    label="成交价"
                    prop="outbidprice"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.outbidprice }} {{ scope.row.unit }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="溢价率"
                    prop="yjl"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    label="出价总次数"
                    prop="count"
                    align="center"
                  >
                    <template slot-scope="scope" >
                      <span v-if="scope.$index==0"> {{ scope.row.count ? scope.row.count : 1 }} 次</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </el-card>
        <!-- 竞价记录 -->
        <el-card v-if="outbidstate == 2">
          <div class="item-4">

            <div class="item-4-table" align="center">
              <div>
                <div class="item-4-prompt p-2" style="background-color: #fff">
                  <i class="el-icon-medal"></i>竞价记录
                </div>
                <el-table
                  class="w-100 dis-ib"
                  style="width: 100%"
                  :data="participatelistRes"
                >
                  <el-table-column prop="bid" label="竞价结果" align="center">
                    <template slot-scope="scope">
                      <el-tag
                        effect="dark"
                        :type="scope.row.bid === '1' ? 'danger' : 'info'"
                        disable-transitions
                      >
                        <span v-if="scope.row.bid === '1'">已中标</span>
                        <span v-else>未中标</span>
                      </el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="出价人"
                    prop="membername"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span>{{ getMemberName(scope.row) }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="出价价格"
                    prop="outbidprice"
                    align="center"
                  ></el-table-column>
                  <el-table-column
                    label="中标数量"
                    prop="bidquantity"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.bidquantity?scope.row.bidquantity:0 }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="出价时间"
                    prop="soutbidtimesStr"
                    align="center"
                  ></el-table-column>
                </el-table>
                <el-button @click="getlist(1)" v-show="!isShowBtn"
                  >显示更多</el-button
                >
                <el-button @click="getlist(2)" v-show="isShowBtn"
                  >收起更多</el-button
                >
              </div>
            </div>
          </div>
        </el-card>
        <el-dialog title="竞拍过程" :visible.sync="dialogVisible">
          <el-table
            class="w-100 dis-ib"
            style="width: 100%"
            :data="participatelist"
          >
            <el-table-column label="出价人" prop="membername" align="center">
              <template slot-scope="scope">
                <span>{{ getMemberName(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="出价价格" prop="outbidprice" align="center">
              <!--<span class="currentPrice" style="color: #F56C6C" v-if="outbidstate === 0"-->
              <!--&gt;{{ price}}<span>{{unitname}}</span></span>-->
              <!--                            <span class="currentPrice" style="color: #F56C6C" v-else-->
              <!--                            >{{ newprices }}<span>{{unitname}}</span></span-->
              <!--                            >-->
            </el-table-column>
            <el-table-column label="数量" prop="outbidquantity" align="center">
            </el-table-column>
            <el-table-column
              label="出价时间"
              prop="soutbidtimesStr"
              align="center"
            ></el-table-column>
          </el-table>
        </el-dialog>

        <div class="productinfo">
          <el-card>
            <el-tabs v-model="activeName" @tab-click="handleClick" class="box">
              <div class="smline"></div>
              <el-tab-pane label="承诺函" name="first">
                <p
                  v-html="bidnotice"
                  class="chengnuohan1"
                  style="height: 100vh; overflow-x: hidden"
                ></p>
              </el-tab-pane>
              <el-tab-pane label="竞买人须知" name="third">
                <p
                  v-html="bidthings"
                  style="height: 100vh; overflow-x: hidden"
                ></p>
              </el-tab-pane>
              <el-tab-pane label="标的物介绍" name="five"
                ><p
                  v-html="recommend"
                  style="height: 100vh; overflow-x: hidden"
                ></p>
              </el-tab-pane>
              <el-tab-pane label="附件" name="six">
                <div style="height: 100vh">
                  <el-table
                    :data="fileInfos1"
                    style="width: 100%"
                    max-height="250"
                  >
                    <el-table-column prop="name">
                      <template slot-scope="scope">
                        <a :href="myurl(scope.row.url)">{{ scope.row.name }}</a>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="disguizedalog"
      width="50%"
      :before-close="handleClose"
    >
      <div class="checkedgz">
        <div>
          <div><span>承诺函</span></div>
          <div>
            <p
              class="article"
              v-html="bidnotice"
              style="height: 100%; overflow-x: hidden"
            ></p>
          </div>
        </div>
        <!-- <div>
                    <div><span>竞买公告</span></div>
                    <div>
                        <p class="article" style="height: 100%;overflow-x: hidden;">
                            受委托，我公司定于<span>{{this.begintime}}</span>在“中国资产交易网”网络竞价平台对<span>{{this.targetname}}</span>一批
                            <span>{{mytargetType(this.targettypecascader)}}</span>进行公开竞价处置。
                            具体预展时间、地点另行短信通知。有意竞买者请交纳竞买保证金并携带有效证件办理竞买手续，银行转账以实际到账为准。
                        </p>
                    </div>
                </div> -->
        <div>
          <div><span>竞买人须知</span></div>
          <div>
            <p
              class="article"
              style="height: 100%; overflow-x: hidden"
              v-html="bidthings"
            >
              意向受让方须在参与竞买前详细阅读《竞买人须知》，了解本须知的全部内容。
              本次网络竞价活动遵循“公开、公平、公正、诚实守信”的原则开展，参加本次网络竞价活动的当事人必须遵守本须知的各项条款，并对自己的行为承担法律责任。
            </p>
          </div>
        </div>
        <div>
          <div><span>竞价主体确认</span></div>
          <div>
            <!--                        <p class="article" style="height: 100%;overflow-x: hidden;" >-->
            <!--                        <p></p>-->
            <p>
              竞价主体: <span>{{ this.name }}</span>
            </p>
            <p>
              证件号码: <span>{{ this.code }}</span>
            </p>
            <p>
              手机号码: <span>{{ this.tel }}</span>
            </p>
            <p style="color: red; font-size: 12px; margin-top: 20px">
              *如有信息错误 请到用户中心-->用户基本信息修改*
            </p>
          </div>
        </div>
      </div>
      <el-checkbox v-model="checkedgz">我已阅读并同意</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="beforebaoming2()">立即报名</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import UploadService from "../services/UploadFilesService";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import {filterObjGetIndex} from "../utils/index"
export default {
  data() {
    return {
      survey:'',
      baoMingParam: "报名以及缴纳保证金",
      dialogStyle: false,
      power: 0,
      htype: 1,
      dis: false,
      shifouhanshui: "11",
      endtime2: "",
      memberidqt: "",
      memberidht: "",
      memberidqtdis: false,
      fileInfos1: [],
      backlist: "",
      targettypecascader: "",
      checkedgz: false,
      // 联系人
      consultation: "",
      unitname: "",
      surveyData: {
        surveyname: "",
        surveyentname: "",
        tel: "",
        targetid: "",
        memberid: "",
      },
      // 踏勘申请 dialog 隐藏框
      surveydialogFormVisible: false,
      //竞拍过程
      dialogVisible: false,
      // 是否收藏
      isCollections: false,
      inputjiage: 0, //
      //
      inputQuantity: 0, //
      shenheflag: {}, // 审核按钮
      myNewTime: null, // 系统时间 - 时间差(系统时间-java时间)
      // 距离结束
      countDownTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      intervalflag: null, //定时器
      imageUrl: "",
      url: "",
      selectedFiles: undefined,
      selectedFiles2: undefined,
      currentFile: undefined,
      currentFile2: undefined,
      progress2: 0,
      progress: 0,
      message: "",
      fileInfos: [],
      fileInfos2: [],
      datafile: undefined,
      depfile: undefined,
      readonly1: false,
      readonly2: false,
      readonly3: false,
      fileaddress: "",
      baomingButton: "下一步", // 报名按钮
      baomingButtondis: false,
      activeName: "first",
      active: 0,
      centerDialogVisible: false,
      baomingVisible: false,
      systemTime: new Date(), //获取java服务器时间
      //当前价格
      newprices: 0.0,
      // 拆分拍 当前报量
      newQuantity: 0.0,
      imgSrc: require("../assets/imgs/zanwutupian.jpg"), //默认显示大图
      imagesList: [require("../assets/imgs/zanwutupian.jpg")],
      fileAddress: "",
      // 标的物所在地中文名称
      areaname: "",
      //标的物名称
      targetname: "",
      projectno: "",
      shw: "",
      shwword: "",
      //挂牌价
      price: 0.0,
      //加价幅度
      minmarkup: 0.0,
      minbquantity: 0.0,
      minquantity: 0.0,
      //类型
      //底价可见（1、低价可见，2低价不可见）
      pricestates: "",
      //自由竞价
      freebidcycle: "",
      //保证金
      deposit: "",
      //评估价
      evaprice: 0.0,
      //竞价状态
      outbidstate: 0,
      outbidstatedis: 0,
      //限时竞价周期
      limitedbidcycle: "",
      // 预估量
      forecast: "",
      //优先购买权
      purchase: "",
      //服务费
      servicecharge: "",
      //保留价
      retentionprice: 0.0,
      //增值税费
      valueaddedtax: 0.0,
      //处置单位
      disposalenterprise: "",
      //咨询方式
      consultationmode: "",
      //拍卖师
      auctioneer: "",
      //拍卖师资质
      auctioneerFileaddress: "",
      //重要提示
      importantnote: "",
      //承诺函=承诺函
      bidnotice: "",
      //竞价须知
      bidthings: "",
      //标的物介绍
      recommend: "",
      //相关附件
      //竞价开始时间
      begintime: "",
      //竞价结束时间
      endtime: "",
      //用户名
      name: "",
      //用户证件号
      code: "",
      //用户名手机号
      tel: "",
      disguizedalog: false, //报名前
      startTime: null, // 定时器起始-终止时间
      // detailsStartTime: "",
      // detailsEndTime: "",
      // detailsCreatTime: "",
      //标的物查询用字段
      targetForm: {
        id: "",
        outbidstate: 0,
        memberid: 0,
        targetid: 0,
      },
      servertime: "",
      //我的最新出价价格
      biddingPrice: "",
      //默认折叠面板展开的面板
      activeNames: ["1"],
      // 公示期(保证金截至时间)
      depositendtime: null,
      //交易属性table数据
      transactionAttributes: [
        {
          data01: "--",
          deposit: "",
          depositendtime: "",
          supervisetel: "",
          deliverydetail: "",
          delivery: "",
          origin: "",
          origindetail: "",
          trtime: "",
          data07: "--",
          data08: "--",
        },
      ],
      //竞价规则table数据
      biddingRules: [
        {
          data01: "公开竞价",
          begintime: "",
          endtime: "",
          data04: "",
          data05: "",
          data06: "",
          data07: "按自报价",
        },
      ],
      //竞价区-竞价状态  true:已结束   false:竞价中
      biddingState: false,
      //竞价区-竞价倒计时
      biddingCountdown: "",
      //竞价过程数据
      participatelist: [],
      // 竞价记录数据
      participatelistRes: [],
      //竞价结果table数据
      participate: [],
      participateLength: null,
      particuser: [],
      participateing: [],
      guochengList: [],
      //code
      loginCode: [],
      origindetail: "",
      origin: "",
      //竞价区-竞价结束时间
      biddingTime: "",
      //竞价区-单价table数据
      unitPrice: [
        {
          price: "",
          minmarkup: "",
          minbquantity: "",
          lapsecount: "",
          lapsemaxcount: "",
          residual: "",
        },
      ],
      //竞价报名状态
      templist: [],
      signUpflg: false,
      signUpstates: false,
      outbidstateflg: false,
      //竞价区-报价table数据
      quotedPrice: [{ data01: "0.00", data02: "0.00", data03: "0" }],
      enterpriseName: "",
      priceCallList: {},

      offerProcess: "",
      bidNumber: "",
      user_id: "",
      bidCount: [],
      totalSumA: {
        sumCnt: "",
      },

      websocketData: {},
      pageClose: false, // 页面是否关闭
      readyState: 0,
      remaining: "", //竞拍剩余时间(秒)
      count: "",
      start: "",
      interval: 1000,
      timeout: "",
      memberid: "",
      param: {
        targetid: "",
        memberid: "",
      },
      saveBid: {
        targetid: "",
        memberid: "",
        fileaddress: "",
      },
      imagedataForm: {
        //会员id
        memberid: "",
        // 图片地址
        fileaddres: "",
        // 联系人
        contacts: "",
        // tel
        tel: "",
      },
      baomingflg: 0,
      baomingflgtemp: 0,
      isShowBtn: false,
      //报名按钮状态判断：0:报名 1:保证金 2:等待出价99:没有权限，请登录
      // 1：企业2全部用户
      isloading: null,
      bmxz: null,
    };
  },
  computed: {
    // destroyed: function () {
    //   wsConnection.closeWebsocket();
    // },
    dataRule() {
      return {
        surveyname: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        surveyentname: [
          { required: true, message: "企业名称不能为空", trigger: "blur" },
        ],
        tel: [{ required: true, message: "电话不能为空", trigger: "blur" }],
      };
    },
  },
  created() {},
  mounted() {
    document.addEventListener("visibilitychange", async () => {//注意兼容性
      if (document.visibilityState === "visible") {//页面被切到前台-显示
        this.countDownTime = "--天--小时--分--秒"
        this.getNewTime(()=>{
          this.changeShowDetails()
        });
      }
    });
  },
  activated() {
    if (window.localStorage.getItem("vuex")) {
      this.backlist = JSON.parse(
        window.localStorage.getItem("vuex")
      ).user.user.blacklist;
    }

    if (this.intervalflag) clearInterval(this.intervalflag);
    this.getNewTime();
    this.memberid = this.getUser().id;
    Object.assign(this.$data, this.$options.data());
    //获取竞价商品的ID
    this.targetForm = this.$route.query;
    // this.$set(this.targetForm, 'memberid', this.getUser().id)
    this.getDetails();
    this.getDetailsPic();
    // 出价  报名  按钮数据
    // 查询收藏
    this.getIsCollections();
  },
  beforeDestroy() {
    clearInterval(this.intervalflag);
  },
  destroyed() {
    clearInterval(this.intervalflag);
  },
  watch: {
    "$route.path": function (newVal, oldVal) {
      if (newVal != "/auction_spliit") {
        clearTimeout(this.priceCallList);
        console.log("$route.path watch");
      }
      if (newVal != "/auction_spliit" || newVal != "/product") {
        if (this.$root.websock && this.$root.websock.readyState === 1) {
          this.$root.websock.close();
          clearTimeout(this.$root.timeoutObj);
          clearTimeout(this.$root.serverTimeoutObj);
        }
      }
    },
  },
  methods: {
    subtempabc(e) {
      if (this.htype === 0) {
        return "吨";
      } else {
        const temp = e.indexOf("/");
        if (temp != -1) {
          return e.substring(temp + 1);
        } else {
          return e;
        }
      }
    },
    // 获取竞价记录计算：  已中标记录 + 竞价过程记录 = （前面2个list的差集 + 已中标记录）
    getlist(flag) {
      // console.log("我走了吗---flag");
      if (flag === 1) {
        this.$post("/shoppingui/bidShopping/EcDTO", this.targetForm).then(
          (data) => {
            // console.log("我走了吗post");
            if (Number(data.code) === 0) {
              // this.dialogVisible = true
              // this.participatelist = data.data;
              // this.participate = this.participate.concat(this.participatelist);
              let GuoChengList = data.data;
              let arr1 = GuoChengList;
              let arr2 = this.participate;
              let differenceSet = [...arr1];
              arr1.forEach((x) => {
                let flag = arr2.some(
                  (y) =>
                    x.memberid == y.memberid && x.outbidprice == y.outbidprice
                );
                if (flag) {
                  //如果有相同的就删除
                  differenceSet.splice(
                    differenceSet.findIndex(
                      (item) =>
                        x.memberid == item.memberid &&
                        x.outbidprice == item.outbidprice
                    ),
                    1
                  );
                }
              });
              // console.log("差集", differenceSet);
              this.participatelistRes = this.participate.concat(differenceSet);
              // console.log("差集itemG", this.participatelistRes);
              //合并完数据再切换按钮
              this.isShowBtn = true;
            } else if (Number(data.code) === 2000) {
              this.dialogVisible = false;
              this.$message({
                message: "请登录后查看",
                offset: 130,
                type: "error",
              });
            }
          }
        );
      } else if (flag === 2) {
        //收起更多
        this.isShowBtn = false;
        // this.participate = this.participate.slice(0, this.participateLength);
        this.participatelistRes = this.participate;
      }
    },

    //导入vuex中的方法
    ...mapGetters(["getUser", "getToken"]),
    myurl(url) {
      if (url != null) {
        var reg = RegExp(/shoppingui/);
        if (reg.test(url)) {
          const index = url.indexOf("shoppingui");
          const res = this.$urldown + url.substring(index);
          return res;
        }
      } else {
        return null;
      }
    },
    chUnit(e) {
      return e;
      // if (e == '11') {
      //     return '元/吨';
      // } else if (e == '12') {
      //     return '元';
      // } else {
      //     return e;
      // }
    },
    // 踏勘申请显示隐藏页
    application() {
      const h = this.$createElement;
      this.$alert(
        h(
          "span",
          { style: "color: red;" },
          "请于公示期结束前3个工作日，完成踏勘申请!"
        ),
        "重要提示",
        {
          confirmButtonText: "点击申请",
          callback: (action) => {
            if (!this.getUser()) {
              this.$message({
                message: "请登录！",
                offset: 130,
                type: "error",
              });
              return false;
            }
            this.surveydialogFormVisible = true;
            this.surveyData.surveyname = "";
            this.surveyData.surveyentname = "";
            this.surveyData.tel = "";
          },
        }
      );
    },
    // 点击确认上传踏勘申请
    surveydialogSubmit() {
      this.$refs["surveyData"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.surveyData.memberid = this.getUser().id;
        this.surveyData.targetid = this.targetForm.targetid;
        this.$post(
          "/shoppingui/bidShopping/saveSurveyapplication",
          this.surveyData
        )
          .then((data) => {
            if (data.code !== 0) {
              this.$message({
                message: "保存失败",
                offset: 130,
                type: "success",
                showClose: "true",
              });
              return false;
            }
            this.surveydialogFormVisible = false;
            this.$message({
              message: "保存成功",
              offset: 130,
              type: "success",
              showClose: "true",
            });
          })
          .catch(() => {});
      });
    },
    // 查询收藏
    getIsCollections() {
      const req = {};
      this.$set(req, "targetid", this.targetForm.targetid);
      this.$set(req, "memberid", this.getUser().id);
      this.$post("/shoppingui/bidShopping/getIsCollections", req).then(
        (data) => {
          if (data.code == 0) {
            this.isCollections = data.data;
          }
        }
      );
    },
    // 点击收藏
    collections(param) {
      if (!this.getUser()) {
        this.$message({
          message: "请登录！",
          offset: 130,
          type: "error",
        });
        return false;
      }
      // 收藏 1  取消收藏 2
      let url = "/shoppingui/bidShopping/deleteCollections";
      let conll = "取消";
      if (param == 1) {
        conll = "";
        url = "/shoppingui/bidShopping/saveCollections";
      }
      this.$confirm("确定" + conll + "收藏此项目吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const req = {};
          this.$set(req, "targetid", this.targetForm.targetid);
          this.$set(req, "memberid", this.getUser().id);

          this.$post(url, req).then((data) => {
            if (data.code == 0) {
              this.getIsCollections();
              // this.$message({
              //   message: '收藏成功',
              //   offset: 130,
              //   type: "success",
              //   showClose: "true",
              // });
            }
          });
        })
        .catch(() => {});
    },
    baojia() {
      if (this.backlist == 1) {
        this.$message({
          message: "您已被系统列入黑名单，无法报名",
          offset: 130,
          type: "error",
        });
      }
      if (
        Number(this.inputjiage) - Number(this.newprices) >=
        Number(this.minmarkup) * 10
      ) {
        this.$confirm("加价幅度超出加价幅度十倍确定继续报价吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.baojiaMath();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消报价",
            });
          });
      } else if((Number(this.inputjiage) <= Number(this.newprices))&&(Number(this.inputQuantity) <= Number(this.newQuantity))){
        this.$message({
          showClose: true,
          message: '价格低于或等于上次报价，必须加量！',
          type: 'error'
        });
      } else {
        this.baojiaMath();
      }
    },
    baojiaMath() {
      const req = {};
      this.$set(req, "targetid", this.targetForm.targetid);
      this.$set(req, "memberid", this.getUser().id);
      this.$set(req, "outbidprice", this.inputjiage);
      this.$set(req, "outbidquantity", this.inputQuantity);
      this.$post("/shoppingui/bidShopping/addOutbidprocessinfo", req).then(
        (data) => {
          if (data.code == 0) {
            this.$message({
              message: "报价已提交！",
              offset: 130,
              type: "success",
              showClose: "true",
            });
          } else {
            this.$message({
              // message: '本次报价与上次报价相同：' + this.inputjiage + '元，提交失败！',
              // message: '本次报价超时，提交失败！',
              message: data.msg,
              offset: 130,
              duration: 3000,
              type: "error",
              showClose: "true",
              onClose: () => {
                location.reload();
              },
            });
          }
        }
      );
    },
    // 页面初始化时 查询 报名资料和保证金状态:报名  active 切换
    getChujia() {
      this.$post(
        "/shoppingui/bidShopping/getDataDepoState",
        this.targetForm
      ).then((data) => {
        const _this = this;
        _this.shenheflag = data.data;

        if (_this.shenheflag != null) {
          _this.baoMingParam = "报名以及缴纳保证金";
          _this.dialogStyle = false;

          if (_this.shenheflag.datastate == 0) {
            _this.baomingflg = 1;
            _this.active = 0;
            if (_this.shenheflag.dataFileAddress) {
              _this.datafile = this.shenheflag.dataFileAddress;
              if (_this.datafile) {
                UploadService.getFiles(_this.datafile).then((response) => {
                  _this.fileInfos = response.data;
                });
              }
            }
          } else if (_this.shenheflag.datastate == 2) {
            _this.baomingflg = 1;
            _this.active = 0;
            _this.baoMingParam = "报名资料驳回原因：" + this.shenheflag.erroMsg;
            _this.dialogStyle = true;
            if (_this.shenheflag.dataFileAddress) {
              _this.datafile = _this.shenheflag.dataFileAddress;
              if (_this.datafile) {
                UploadService.getFiles(_this.datafile).then((response) => {
                  _this.fileInfos = response.data;
                });
              }
            }
          } else if (
            _this.shenheflag.datastate == 1 &&
            _this.shenheflag.depostate == null
          ) {
            _this.baomingflg = 2;
            _this.active = 2;
            if (_this.shenheflag.depFileAddress) {
              _this.depfile = _this.shenheflag.depFileAddress;
              if (_this.depfile) {
                UploadService.getFiles(_this.depfile).then((response) => {
                  _this.fileInfos2 = response.data;
                });
              }
            }
          } else if (
            _this.shenheflag.datastate == 1 &&
            _this.shenheflag.depostate == 0
          ) {
            _this.baomingflg = 4;
            _this.active = 2;
            if (_this.shenheflag.depFileAddress) {
              _this.depfile = _this.shenheflag.depFileAddress;
              if (_this.depfile) {
                UploadService.getFiles(_this.depfile).then((response) => {
                  _this.fileInfos2 = response.data;
                });
              }
            }
          } else if (
            _this.shenheflag.datastate == 1 &&
            _this.shenheflag.depostate == 1
          ) {
            if (_this.outbidstate == 0) {
              _this.baomingflg = 6;
            } else if (_this.outbidstate == 5) {
              _this.baomingflg = 7;
            } else {
              _this.baomingflg = 5;
            }
            _this.active = 99;
          } else if (
            _this.shenheflag.datastate == 1 &&
            _this.shenheflag.depostate == 2
          ) {
            _this.baomingflg = 2;
            _this.active = 2;
            _this.baoMingParam = "保证金驳回原因：" + _this.shenheflag.depmsg;
            _this.dialogStyle = true;
            if (_this.shenheflag.depFileAddress) {
              _this.depfile = _this.shenheflag.depFileAddress;
              if (_this.depfile) {
                UploadService.getFiles(_this.depfile).then((response) => {
                  _this.fileInfos2 = response.data;
                });
              }
            }
          }
        } else {
          _this.baomingflg = 0;
          _this.active = 0;
          // _this.depfile = new Date().getTime();
        }
      });
    },
    // 图片上传成功
    handleAvatarSuccess(res, file) {
      this.imagedataForm.fileaddres = res.data.url;
      this.imageUrl = this.$ImgUrl + res.data.url;
      this.baomingButtondis = true;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传附件
    selectFile() {
      this.selectedFiles = this.$refs.file.files;
    },
    selectFile2() {
      this.selectedFiles2 = this.$refs.file2.files;
    },

    upload(e) {
      const _this = this;
      this.progress = 0;
      this.currentFile = e.file;
      if (!_this.datafile) {
        _this.datafile = new Date().getTime();
      }
      UploadService.upload(
        this.currentFile,
        (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        },
        _this.datafile
      )
        .then((response) => {
          this.message = response.data.message;
          this.baomingButtondis = true;
          //return UploadService.getFiles(_this.datafile);
        })
        .then((files) => {
          this.fileInfos = files.data;
          this.baomingButtondis = true;
        })
        .catch(() => {
          this.progress = 0;
          this.message = "Could not upload the file!";
          this.currentFile = undefined;
        });
      this.selectedFiles = undefined;
    },
    upload2(e) {
      const _this = this;
      this.progress2 = 0;
      this.currentFile2 =  e.file;
      if (!_this.depfile) {
        _this.depfile = new Date().getTime();
      }
      UploadService.upload(
        this.currentFile2,
        (event) => {
          this.progress2 = Math.round((100 * event.loaded) / event.total);
        },
        _this.depfile
      )
        .then((response) => {
          this.message = response.data.message;
          this.baomingButtondis = true;
          //return UploadService.getFiles(_this.depfile);
        })
        .then((files) => {
          this.fileInfos2 = files.data;
          this.baomingButtondis = true;
        })
        .catch(() => {
          this.progress2 = 0;
          this.message = "Could not upload the file!";
          this.currentFile2 = undefined;
        });
      this.selectedFiles2 = undefined;
    },
    //文件链接
    openLink(file){
      window.open(file.url)
    },
    removeElement(file, fileList) {
      return new Promise((response,reject)=>{
        let fileName = file.name;
        let index = filterObjGetIndex(fileList,'uid',file.uid)
        // this.$refs.file.value = ''
        this.$confirm("确认永久删除" + fileName + "吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            UploadService.delete(this.datafile, fileName).then((response) => {
              if (response.data === true) {
                fileList.splice(index, 1);
                this.baomingButtondis = true;
                if (fileList != null && fileList.length == 0) {
                  this.baomingButtondis = false;
                }
              } else {
                this.message = "Delete file was failed";
              }
            });
          })
          .catch(() => {
            return reject();
          });
      })

    },
    removeElement2(file, fileList) {

      return new Promise((response,reject)=>{
        let fileName = file.name;
        let index = filterObjGetIndex(fileList,'uid',file.uid)
        // this.$refs.file.value = ''
        this.$confirm("确认永久删除" + fileName + "吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            UploadService.delete(this.depfile, fileName).then((response) => {
              if (response.data === true) {
                fileList.splice(index, 1);
                this.baomingButtondis = true;
                if (fileList != null && fileList.length == 0) {
                  this.baomingButtondis = false;
                }
              } else {
                this.message = "Delete file was failed";
              }
            });
          })
          .catch(() => {
            return reject();
          });
      })
    },
    next() {
      if (this.active == 0) {
        this.baomingflg = 1;
        this.nextSave();
        this.baomingButtondis = false;
      }
      if (this.active == 1) {
        this.baomingflg = 1;
        this.nextSave();
        this.baomingButtondis = false;
      }
      if (this.active == 2) {
        this.baomingflg = 4;
        this.saveDepImage();
        this.baomingButtondis = false;
      }
      // this.active++
    },
    // 点击下一步（我要报名）
    nextSave() {
      this.saveBid.targetid = this.targetForm.id;
      this.saveBid.memberid = this.getUser().id;
      this.saveBid.fileaddress = this.datafile;
      this.$post("/shoppingui/bidShopping/shoppingSaveWeb", this.saveBid).then(
        (data) => {
          if (data.code == 0) {
            // if(this.fileInfos.length>0){
            //   this.baomingButtondis = true
            // }
            this.$message({
              message: data.msg,
              offset: 130,
              type: "success",
              showClose: "true",
            });
          } else {
            this.baomingButtondis = false;
            this.$message({
              message: data.msg + "1002",
              offset: 130,
              type: "error",
              showClose: "true",
            });
          }
          this.baomingVisible = false;
        }
      );
    },
    // 打开 报名资料和 保证金的  dilog
    // getDataDept() {
    //     console.log(111111111111)
    //     // this.getChujia();
    // },
    // 切换竞价商品明细
    handleClick(tab, event) {
      if (tab.name == "six") {
        if (this.fileAddress) {
          UploadService.getFilesa1(this.fileAddress).then((response) => {
            this.fileInfos1 = response.data;
          });
        }
      }
    },
    // 获取服务器得时间
    getNewTime(call) {
      this.$post("/shoppingui/bidShopping/getsysTime").then((data) => {
        if (data.code == 0) {
          this.systemTime = data.data;
          console.log("服务器时间"+this.systemTime)
        } else {
          this.$message({
            message: data.message + "1001",
            offset: 130,
            type: "error",
            showClose: "true",
          });
        }
        if(call){
          call();
        }
      });
    },
    //竞拍价格变化触发事件
    changePrice(val) {
      if ((Number(val) - Number(this.price)) % Number(this.minmarkup) != 0) {
        this.$message({
          message: "您修改的数量不是最小竞价基价的倍数",
          offset: 130,
          type: "error",
          showClose: "true",
        });
        return false;
      }
      this.biddingPrice = val;
      // this.currentPrice = val;
    },

    mine(value) {
      this.$router.push({
        path: "/personalCenter",
        query: {
          values: value,
        },
      });
    },
    //上传保证金截图
    saveDepImage() {
      this.imagedataForm.targetid = this.targetForm.id;
      this.imagedataForm.memberid = this.getUser().id;
      this.imagedataForm.contacts = this.getUser().id;
      this.imagedataForm.tel = this.getUser().tel;
      this.imagedataForm.fileaddres = this.depfile;
      this.$post(
        "/shoppingui/bidShopping/saveDeposhop",
        this.imagedataForm
      ).then((data) => {
        if (data.code == 0) {
          this.baomingVisible = false;
        } else {
          this.$message({
            message: data.msg + "1003",
            offset: 130,
            type: "error",
            showClose: "true",
          });
        }
      });
    },
    //报名前
    beforebaoming() {
      this.isloading = JSON.parse(
              window.localStorage.getItem("vuex")
      ).user.user.isloading;

      this.backlist = JSON.parse(
        window.localStorage.getItem("vuex")
      ).user.user.blacklist;
      if (this.backlist == 0) {
        if (this.bmxz  == 1 && this.isloading == 0){
          this.$message({
            message: "本项目要求使用企业账号报名！",
            offset: 130,
            type: "error",
          });
        } else {
          if (
                  JSON.parse(window.localStorage.getItem("vuex")).user.user.isloading ==
                  0
          ) {
            this.name = JSON.parse(
                    window.localStorage.getItem("vuex")
            ).user.user.membername;
            this.code = JSON.parse(
                    window.localStorage.getItem("vuex")
            ).user.user.membercode;
            this.tel = JSON.parse(
                    window.localStorage.getItem("vuex")
            ).user.user.tel;
          } else if (
                  JSON.parse(window.localStorage.getItem("vuex")).user.user.isloading ==
                  1
          ) {
            this.name = JSON.parse(
                    window.localStorage.getItem("vuex")
            ).user.user.membername;
            this.code = JSON.parse(
                    window.localStorage.getItem("vuex")
            ).user.user.legalcode;
            this.tel = JSON.parse(
                    window.localStorage.getItem("vuex")
            ).user.user.tel;
          }
          this.disguizedalog = true;
          this.checkedgz = false;
        }

      } else {
        this.$message({
          message: "您已被系统列入黑名单，无法报名",
          offset: 130,
          type: "error",
        });
      }
    },
    beforebaoming2() {
      if (this.checkedgz) {
        this.baoming();
      } else {
        this.$message({
          message: "请阅读并同意后报名",
          offset: 130,
          type: "error",
        });
      }
    },
    //报名按钮
    baoming() {
      this.disguizedalog = false;
      console.log(this.baomingButtondis)
      this.baomingButtondis = false;
      if (
        localStorage.getItem("token") == null ||
        localStorage.getItem("token") == ""
      ) {
        this.$message({
          message: "请登录",
          offset: 130,
          type: "error",
          showClose: "true",
        });
      } else {
        // 点击我要报名之前，先看是否已经报过名:0:为报名，1:已报名并未审核，2:已报名并审核等待缴纳保证金 3:
        this.url = this.$ImgUrl + "/bidShopping/imgUpload";
        const timestamp = new Date().getTime();
        // this.fileaddress = timestamp

        this.baomingVisible = true; //报名弹出框
        if (this.active == 0) {
          this.baomingButton = "提交资料";
          if (this.fileInfos.length > 0) {
            this.baomingButtondis = true;
          }
        }
        if (this.active == 1) {
          this.baomingButton = "提交资料";
          if (this.fileInfos.length > 0) {
            this.baomingButtondis = true;
          }
        }
        if (this.active == 2) {
          this.baomingButton = "提交保证金";
          if (this.imageUrl.length > 0) {
            this.baomingButtondis = true;
          }
        }
      }
    },
    uploadMoreFile() {
      this.baoming();
    },
    getdeposAuditstate() {
      this.$post("/shoppingui/bidShopping/getdeposAuditstate", this.param).then(
        (data) => {
          if (data.code == 0) {
            if (data.data.auditstate == 0) {
              this.baomingflg = 4; //保证金未审核
              this.active = 3;
            }
            if (data.data.auditstate == 1) {
              this.baomingflg = 5; //保证金已审核
            }
            if (data.data.auditstate == 2) {
              this.baomingflg = 6; //保证金已驳回
            }
          } else {
            this.$message({
              message: data.message + "1005",
              offset: 130,
              type: "error",
              showClose: "true",
            });
          }
        }
      );
    },
    //是否报名和保证金状态验证
    getCount() {
      this.param.targetid = this.targetForm.id;
      this.param.memberid = this.getUser().id;
      this.$post("/shoppingui/bidShopping/getBidCount", this.param).then(
        (data) => {
          if (data.code == 0) {
            if (data.data.count > 0) {
              if (data.data.auditstate == 0) {
                this.baomingflg = 1; //已报名未审核
                this.active = 1;
              }
              if (data.data.auditstate == 1) {
                this.baomingflg = 2; //已报名已审核等待缴纳保证金
                this.active = 2;
                this.getdeposAuditstate();
              }
              if (data.data.auditstate == 2) {
                this.baomingflg = 3; //已驳回
              }
            } else {
              this.baomingflg = 0; //未报名
              this.active = 0;
            }
          } else {
            this.$message({
              message: data.message + "1006",
              offset: 130,
              type: "error",
              showClose: "true",
            });
          }
        }
      );
    },
    getDetailsPic() {
      this.imgSrc = require("../assets/imgs/zanwutupian.jpg");
      this.imagesList = [require("../assets/imgs/zanwutupian.jpg")];
      this.$post(
        "/shoppingui/bidShopping/getTargetImageByid",
        this.targetForm
      ).then((data) => {
        if (data.code == 0) {
          //图片接入
          if (data.data.length > 0) {
            this.imagesList = [];
            this.imgSrc = this.$ImgUrl + data.data[0];
            this.templist = data.data;
            this.templist.forEach((i) => {
              this.imagesList.push(this.$ImgUrl + i);
            });
          }
        }
      });
    },
    getDetails() {
      this.$post(
        "/shoppingui/bidShopping/getTargetinfoByid_splite",
        this.targetForm
      ).then((data) => {
        console.log(data, "DATA");
        if (data.code == 0) {
          // 是否强制踏勘
          this.survey = data.data.survey;
          // 计费方式
          this.power = data.data.power;
          // 计费方式
          this.htype = data.data.htype;
          // 标的物所在地中文名称
          this.areaname = data.data.areaname;

          this.targetname = data.data.targetname;
          this.projectno = data.data.projectno;
          this.shw = data.data.shw;
          this.bmxz = data.data.bmxz;
          this.shwword = data.data.shwword;
          //挂牌价
          this.price = data.data.price;
          this.unitname = this.chUnit(data.data.unit);
          this.consultation = data.data.consultation;
          this.newprices = data.data.price.toFixed(3);

          this.newQuantity = data.data.minquantity.toFixed(3);
          this.inputQuantity = data.data.minquantity.toFixed(3);
          if (data.data.shw == 1) {
            this.inputjiage = data.data.price.toFixed(3);
          } else {
            this.dis = true;
            this.inputjiage = 0.0;
          }

          //加价幅度
          this.minmarkup = data.data.minmarkup;
          this.minbquantity = data.data.minbquantity;
          this.minquantity = data.data.minquantity;
          //类型
          //底价可见（1、低价可见，2低价不可见）
          this.pricestates = data.data.pricestates;
          //自由竞价
          this.freebidcycle = data.data.freebidcycle;
          //保证金
          this.deposit = data.data.deposit;
          //评估价
          this.evaprice = data.data.evaprice;
          //竞价状态
          this.outbidstate = data.data.outbidstate;

          if ([2, 3, 4].includes(this.outbidstate)) {
            //服务费
            this.servicecharge = "-";
          } else if ([1].includes(this.outbidstate)) {
            //页面刚进入时开启长连接
            this.initWebSocket();
            //服务费
            this.servicecharge = data.data.servicecharge;
          } else {
            //服务费
            this.servicecharge = data.data.servicecharge;
          }
          //限时竞价周期
          this.limitedbidcycle = data.data.limitedbidcycle;
          // 预估量
          this.forecast = data.data.forecast;
          //优先购买权
          this.purchase = data.data.purchase;

          //保留价
          this.retentionprice = data.data.retentionprice;
          //增值税费
          this.valueaddedtax = data.data.valueaddedtax;
          //处置单位
          this.disposalenterprise = data.data.disposalenterprise;
          //咨询方式
          this.consultationmode = data.data.consultationmode;
          // 是否含税
          this.shifouhanshui = data.data.isaddedtax;

          //拍卖师
          this.auctioneer = data.data.auctioneername;
          //拍卖师资质
          this.auctioneerFileaddress =
            this.$ImgUrl + data.data.auctioneerFileaddress;
          //重要提示
          this.importantnote = data.data.importantnote;
          //承诺函
          this.bidnotice = data.data.bidnotice.replaceAll(
            "http://123.56.177.143:18888/api/businessapi/",
            "https://www.cnassets.com/api/shoppingui/"
          );
          //竞价须知
          this.bidthings = data.data.bidthings.replaceAll(
            "http://123.56.177.143:18888/api/businessapi/",
            "https://www.cnassets.com/api/shoppingui/"
          );
          //标的物介绍
          this.recommend = data.data.recommend.replaceAll(
            "http://123.56.177.143:18888/api/businessapi/",
            "https://www.cnassets.com/api/shoppingui/"
          );

          //相关附件
          // if (this.outbidstate == 5) {
          //     if (data.data.ecBidingDataDTO) {
          //         this.datafile = data.data.ecBidingDataDTO.fileaddress;
          //         if (this.datafile) {
          //             UploadService.getFiles(this.datafile).then((response) => {
          //                 this.fileInfos = response.data
          //             })
          //         }
          //     }
          //     if (data.data.ecDepositinfoDTO) {
          //         this.depfile = data.data.ecDepositinfoDTO.fileaddres
          //         if (this.depfile) {
          //             UploadService.getFiles(this.depfile).then((response) => {
          //                 this.fileInfos2 = response.data
          //             })
          //         }
          //     }
          //
          // }
          //竞价开始时间
          this.begintime = data.data.begintime;
          //竞价结束时间
          this.endtime = data.data.finalendtime;
          this.endtime2 = data.data.endtime;
          const tempfreebidcycle =
            (new Date(this.endtime).getTime() -
              new Date(this.begintime).getTime()) /
            1000 /
            60;
          const tempfreebidcycle2 =
            (new Date(this.endtime2).getTime() -
              new Date(this.begintime).getTime()) /
            1000 /
            60;

          this.freebidcycle = tempfreebidcycle2.toFixed(0);
          //java系统时间
          this.servertime = data.data.servertime;
          this.targettypecascader = data.data.targettypecascader;
          // 保证金截至时间
          this.depositendtime = data.data.depositendtime;

          this.fileAddress = data.data.fileAddress;
          //图片接入
          // if (data.data.thumb.length > 0) {
          //     this.imagesList = []
          //     this.imgSrc = this.$ImgUrl + data.data.thumb[0]
          //     this.templist = data.data.thumb

          //     this.templist.forEach(i => {
          //         this.imagesList.push(this.$ImgUrl + i)
          //     })
          // }
          // 初始化最高价 竞价过程列表
          if (data.data.maxInfo.length > 0) {
            this.guochengList = data.data.maxInfo;
            // this.newprices = (data.data.maxInfo[0].maxPrice).toFixed(3)
            // this.inputjiage = (data.data.maxInfo[0].maxPrice + this.minmarkup).toFixed(3)
            //
            // this.newQuantity = (data.data.maxInfo[0].maxQuantity).toFixed(3)
            // this.inputQuantity = (data.data.maxInfo[0].maxQuantity + this.minbquantity).toFixed(3)
          }
          if (data.data.curMaxInfo != null) {
            // this.guochengList = data.data.maxInfo
            this.newprices = data.data.curMaxInfo.maxPrice.toFixed(3);
            this.inputjiage = data.data.curMaxInfo.maxPrice.toFixed(3);

            this.newQuantity = data.data.curMaxInfo.maxQuantity.toFixed(3);
            this.inputQuantity = data.data.curMaxInfo.maxQuantity.toFixed(3);
          }
          // 竞价结果
          if (data.data.ecOutbidresultinfoDTOList != null) {
            this.memberidqt = this.getUser().id;
            // this.memberidht = data.data.ecOutbidresultinfoDTO.id
            data.data.ecOutbidresultinfoDTOList.forEach((item) => {
              if (item.memberid == this.memberidqt) {
                this.memberidqtdis = true;
              }
            });
            // if(this.memberidqt == this.memberidht){
            //     this.memberidqtdis =true
            // }
            this.participate = [];
            // 竞价结果--拆分拍
            this.participate = data.data.ecOutbidresultinfoDTOList;
            this.participateLength = this.participate.length;
            // 竞价记录--拆分拍
            this.participatelistRes = this.participate;
          }

          if (this.getUser().id) {
            this.getChujia();
            // this.getCount(); //获取报名状态 0:为报名，1:已报名并未审核，2:已报名并审核等待缴纳保证金
            // this.getOfferProcess(); //获取竞价信息
            // this.getBidNumber(); //获取竞价次数
          } else {
            this.baomingflg = 99;
          }
          //竞价开始判断
          // if (this.outbidstate === 0 || this.outbidstate === 1 ) {
          if ([0, 1, 5].includes(this.outbidstate)) {
            //竞价状态
            this.startTime = null;
            if (this.outbidstate == 0) {
              this.startTime = data.data.begintime;
            } else if (this.outbidstate == 1) {
              this.startTime = data.data.finalendtime;
            } else if (this.outbidstate == 5) {
              this.startTime = data.data.depositendtime;
            }
            // 本地时间 定时器 计算时间
            this.nowTime = new Date();
            this.getNewTime();
            //本地时间 - java服务器时间
            // const seconds2 = moment(this.nowTime).diff(this.systemTime, 'seconds');
            // 矫正后得本地时间
            // this.myNewTime = moment(this.nowTime).subtract(seconds2, 'seconds');
            this.myNewTime = this.systemTime;
            if (this.intervalflag) {
              clearInterval(this.intervalflag);
            }
            // 启动 interval
            this.intervalflag = setInterval(() => {
              this.countDownTime = this.countDown();
              this.myNewTime = moment(this.myNewTime).subtract(-1, "seconds");
            }, 1000);

            this.biddingState = false;
          } else {
            this.biddingState = true;
            this.outbidstatedis = 5;
          }
        } else {
          this.$message({
            message: data.message + "1007",
            offset: 130,
            type: "error",
            showClose: "true",
          });
        }
      });
    },
    // 距离结束时间
    countDown() {
      const seconds = moment(this.startTime).diff(this.myNewTime, "seconds");
      if (seconds <= 0) {
        this.baomingflg = 111;
        clearInterval(this.intervalflag);
        // new 20220305 start
        this.refresh();
        return "--天--小时--分--秒";
      }
      let secondst = seconds;
      // console.log('差值'+seconds)
      if (this.outbidstate == 1) {
        if (
          this.shenheflag != null &&
          this.baomingflg != 5 &&
          this.shenheflag.datastate == 1 &&
          this.shenheflag.depostate == 1
        ) {
          this.baomingflg = 5;
        }
        secondst = seconds - 2;
        if (secondst <= 0) {
          this.baomingflg = 111;
          return "--天--小时--分--秒";
        }
        //    console.log('差值'+secondst)
      }
      const second = secondst % 60;
      const minutes = (secondst - second) / 60;
      const minute = minutes % 60;
      const hours = (minutes - minute) / 60;
      const hour = hours % 24;
      const day = (hours - hour) / 24;
      const res =
        day + "天 " + hour + "小时 " + minute + "分 " + second + "秒 ";
      return res;
    },
    //跳转登录页面
    gotoLogin() {
      this.$router.push({ path: "/login" });
    },
    //跳转注册页面
    gotoRegist() {
      this.$router.push({ path: "/register" });
    },
    //刷新当前页面
    refresh() {
      this.$router.go(0);
    },
    reload() {
      this.$forceUpdate();
    },

    initWebSocket() {
      var socketUrl = this.$websocket + "/shoppingui/app/websocket";
      this.$root.websock = new WebSocket(socketUrl);
      this.$root.websock.onopen = this.websocketonopen;
      this.$root.websock.onerror = this.websocketonerror;
      this.$root.websock.onmessage = this.websocketonmessage;
      this.$root.websock.onclose = this.websocketclose;
    },
    websocketonopen(e) {
      console.log("ket连接成功");
      this.websocketstart(e);
    },
    websocketonerror(e) {
      console.log("ket连接发生错误");
      this.websocketreconnect();
    },
    websocketonmessage(e) {
      console.log("接受到消息处理");
      if (e.data !== "heartCheck Auction") {
        this.websocketData = e.data;
        if (
          JSON.parse(e.data)[0].targetidStr ==
          this.targetForm.targetid.toString()
        ) {
          this.getDetails();
          // this.guochengList = JSON.parse(e.data);
          // this.newprices = JSON.parse(e.data)[0].maxPrice.toFixed(3);

          // this.inputjiage = (JSON.parse(e.data)[0].maxPrice + this.minmarkup).toFixed(3)
          //
          // this.newQuantity = (JSON.parse(e.data)[0].maxQuantity).toFixed(3)
          // this.inputQuantity = (JSON.parse(e.data)[0].maxQuantity + this.minbquantity).toFixed(3)

          // 原规则  start
          // const newtimet = JSON.parse(e.data)[0].maxPriceTime
          // console.log(newtimet + '最新出价时间')
          // const temptime = moment(this.startTime).subtract(((this.limitedbidcycle - 1) * 60), 'seconds');
          // // console.log(temptime + '更新出价时间1')
          // // console.log(moment(temptime).format('YYYY-MM-DDTHH:mm') + '更新出价时间2')
          // const seconds222 = newtimet - temptime
          // // console.log(seconds222+ '相差秒数')
          // if (seconds222 >= 0) {
          //     this.refresh()
          // }
          // //原规则 end
          // 新规则 start
          if (JSON.parse(e.data)[0].flag == 1) {
            this.getNewInterval();
          }
          // 新规则 end
        }
      }
      this.websocketreset(e);
    },
    getNewInterval() {
      this.$post(
        "/shoppingui/bidShopping/getTargetinfoByid",
        this.targetForm
      ).then((data) => {
        if (data.code == 0) {
          this.startTime = data.data.finalendtime;
          this.systemTime = data.data.sysdate2;
          this.countDownTime = "--天--小时--分--秒";
          this.getNewTime();
          this.myNewTime = this.systemTime;
          if (this.intervalflag) {
            clearInterval(this.intervalflag);
          }
          // 启动 interval
          this.intervalflag = setInterval(() => {
            this.countDownTime = this.countDown();
            this.myNewTime = moment(this.myNewTime).subtract(-1, "seconds");
          }, 1000);
        }
      });
    },
    websocketclose(e) {
      console.log(e, "ket close");
      //清除时间
    },
    websocketreconnect() {
      console.log("ket重新连接");
      //重新连接
      let that = this;
      if (that.$root.lockReconnect) {
        return;
      }
      that.$root.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.$root.timeoutnum && clearTimeout(that.$root.timeoutnum);
      that.$root.timeoutnum = setTimeout(function () {
        //新连接
        that.initWebSocket();
        that.$root.lockReconnect = false;
      }, 1000);
    },
    websocketreset(e) {
      //重置心跳
      let that = this;
      //清除时间
      clearTimeout(that.$root.timeoutObj);
      clearTimeout(that.$root.serverTimeoutObj);
      //重启心跳
      that.websocketstart(e);
    },
    websocketstart() {
      //开启心跳
      let self = this;
      self.$root.timeoutObj && clearTimeout(self.timeoutObj);
      self.$root.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.$root.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (self.$root.websock.readyState === 1) {
          //如果连接正常
          self.$root.websock.send("heartCheck Auction");
        } else {
          //否则重连
          self.websocketreconnect();
        }
        self.$root.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.websocketclose();
        }, self.$root.timeout);
      }, self.$root.timeout);
    },
    //竞价次数
    getBidNumber() {
      const param = {
        targetid: this.$route.query.id,
      };
      this.$post("/shoppingui/bidShopping/getOutbidCount", param).then(
        (data) => {
          if (data && data.code === 0) {
            this.bidCount = data.data;
          } else {
            this.$message({
              offset: 130,
              message: data.message + "1009",
              type: "error",
              showClose: "true",
            });
          }
        }
      );
    },
    setIndex(arr) {
      for (let i = 0; i < arr.length; i++) {
        this.$set(arr[i], "index", i + 1);
      }
      return arr;
    },
    shifouhanshuiis(e) {
      if (e == "10") {
        return "是";
      } else if (e == "11") {
        return "否";
      } else {
        return "--";
      }
    },
    getBidCount() {
      this.$post(
        "/shoppingui/bidShopping/getOutbidCount",
        { targetid: this.$route.query.id },
        true
      ).then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          for (let k = 0; k < this.participate.length; k++) {
            if (data.data[i].memberid == this.participate[k].memberid) {
              this.$set(this.participate[k], "count", data.data[i].count);
            }
          }
          for (let l = 0; l < this.participateing.length; l++) {
            if (data.data[i].memberid == this.participateing[l].memberid) {
              this.$set(this.participateing[l], "count", data.data[i].count);
            }
          }
        }
      });
    },
    subrprice() {
      if (this.newprices < Number(this.inputjiage) - Number(this.minmarkup))
        this.inputjiage = (
          Number(this.inputjiage) - Number(this.minmarkup)
        ).toFixed(3);
    },
    addprice() {
      this.inputjiage = (
        Number(this.inputjiage) + Number(this.minmarkup)
      ).toFixed(3);
    },
    subrQuantity() {
      //点击减号 出价量 = 现有出价量 - 最低加量
      this.inputQuantity = (Number(this.inputQuantity) - Number(this.minbquantity)).toFixed(3);
      //出价量 小于 最小量
      if (Number(this.inputQuantity) < Number(this.minquantity)) {
        //出价量 = 起拍量
        this.inputQuantity = this.minquantity;
      }
    },
    addQuantity() {
      this.inputQuantity = (
        Number(this.inputQuantity) + Number(this.minbquantity)
      ).toFixed(3);
      //数量最大为 预估量
      if(Number(this.forecast)<Number(this.inputQuantity)){
        this.inputQuantity = this.forecast;
      }
    },
    getMemberName(e) {
      if (this.getUser().id == e.memberid) return e.membername;
      return "******";
    },
    getMemberNamemaxUser(e) {
      if (this.getUser().id == e.memberid) return this.getUser().membername;
      return "******";
    },
    gotohelp() {
      let routeData = this.$router.resolve({ path: "/service" });
      window.open(routeData.href, "_blank");
    },
    mytargetType(e) {
      if (e != null) {
        if (e.substr(0, 2) == "11") {
          return "废旧资材";
        } else if (e.substr(0, 2) == "22") {
          return "闲废设备";
        } else if (e.substr(0, 2) == "33") {
          return "闲废车辆";
        } else if (e.substr(0, 2) == "44") {
          return "工业固废";
        } else if (e.substr(0, 2) == "44") {
          return "租赁";
        } else if (e.substr(0, 2) == "55") {
          return "其他";
        } else {
          return "其他";
        }
      }
    },
    tableRowSetting(row) {
      if (this.getUser().id == row.memberid) {
        return "has-color";
      }
      // 根据if条件定义到该行
      return "";
    },
    getsubstring(e) {
      const inde = e.indexOf("profile");
      const h = e.substring(inde);
      return this.$ImgUrl + h;
    },
    locatofix2(price) {
      return price.toFixed(3);
    },
    //切换页面展示后重新调用
    changeShowDetails(time) {
      this.$post(
        "/shoppingui/bidShopping/getTargetinfoByid_splite",
        this.targetForm
      ).then((data) => {
        if (data.code == 0) {
          //竞价状态
          this.outbidstate = data.data.outbidstate;
          //竞价开始时间
          this.begintime = data.data.begintime;
          //竞价结束时间
          this.endtime = data.data.finalendtime;
          this.endtime2 = data.data.endtime;

          // 保证金截至时间
          this.depositendtime = data.data.depositendtime;
          if (!this.getUser().id) {
            this.baomingflg = 99;
          }
          //竞价开始判断
          // if (this.outbidstate === 0 || this.outbidstate === 1 ) {
          if ([0, 1, 5].includes(this.outbidstate)) {
            //竞价状态
            this.startTime = null;
            if (this.outbidstate == 0) {
              this.startTime = data.data.begintime;
            } else if (this.outbidstate == 1) {
              this.startTime = data.data.finalendtime;
            } else if (this.outbidstate == 5) {
              this.startTime = data.data.depositendtime;
            }
            // 本地时间 定时器 计算时间
            this.nowTime = new Date();
            //this.getNewTime();
            //本地时间 - java服务器时间
            // const seconds2 = moment(this.nowTime).diff(this.systemTime, 'seconds');
            // 矫正后得本地时间
            // this.myNewTime = moment(this.nowTime).subtract(seconds2, 'seconds');
            this.myNewTime = this.systemTime;
            if (this.intervalflag) {
              clearInterval(this.intervalflag);
            }
            // 启动 interval
            this.intervalflag = setInterval(() => {
              this.countDownTime = this.countDown();
              this.myNewTime = moment(this.myNewTime).subtract(-1, "seconds");
            }, 1000);

            this.biddingState = false;
          } else {
            this.biddingState = true;
            this.outbidstatedis = 5;
          }
        } else {
          this.$message({
            message: data.message + "1007",
            offset: 130,
            type: "error",
            showClose: "true",
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "../assets/css/index.css";
@import "../assets/css/page/auction.scss";

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/deep/ .el-input__inner {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
}

.currentPrice {
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 28px;
  font-family: "Times New Roman", Times, serif;

  & > span {
    font-size: 16px !important;
  }
}
.currentPrice2 {
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 28px;
  font-family: "Times New Roman", Times, serif;

  & > span {
    font-size: 16px !important;
  }
}
.currentPrice3 {
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 22px;
  font-family: "Times New Roman", Times, serif;

  & > span {
    font-size: 16px !important;
  }
}

.checkedgz {
  border: 1px solid red;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  & > div {
    font-size: 22px;
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    & > div {
      height: 300px;
      text-align: center;
      flex: 1;
      border: 1px solid rgb(128, 128, 131);

      &:nth-child(2) {
        padding: 30px;
        text-indent: 45px;
        text-align: justify; // 所有行两端对齐
        text-justify: inter-ideograph; // 最后一行居左对齐
        flex: 10;
      }

      & > span {
        text-align: center;
        writing-mode: vertical-lr;
        letter-spacing: 5px;
        margin-top: 100px;
        // writing-mode:horizontal-tb;//默认：水平方向，从上到下
      }
    }
  }
}

.article {
  & > span {
    color: blue;
  }
}
.chengnuohan1 {
  text-indent: 45px;
  /*text-align: justify; // 所有行两端对齐*/
  /*text-justify: inter-ideograph; // 最后一行居左对齐*/
}
.has-color {
  background-color: rgb(120, 149, 247) !important; //加上背景颜色
  //   font-weight:bold; //字体加粗
  height: 228px; //自定义行高
}
::v-deep .el-descriptions__title {
  width: 100% !important;
  text-align: center;
  color: #c09853;
  font-size: 20px;
}
.item-4-prompt {
  background-color: #fff;
  font-family: "Microsoft YaHei";
  font-size: 18px;
  font-weight: 700;
  margin-top: 0 !important;
}
</style>
